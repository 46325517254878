<template>
  <span v-html="result"></span>
</template>

<script>
import DataValue from "@/components/data-value.vue";
export default {
  name: "DataValueSpan",
  extends: DataValue,
  computed: {
    result() {
      return this.$utils.trim(this.formatedDataValue) === ""
        ? "&nbsp;"
        : this.formatedDataValue;
    }
  }
};
</script>

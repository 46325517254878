<template>
  <img v-bind:src="src" class="synoptic-image" :style="style" />
  <!-- <img v-bind:src="src" class="synoptic-image" crossorigin="anonymous" referrerpolicy="origin"/> -->
</template>

<script>
export default {
  name: "SynopticImage",
  props: {
    control: {
      type: Object,
      required: true
    },
    currentRect: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    src: function() {
      return (
        this?.control?.synopticComponent?.tmp?.src ||
        this?.control?.synopticComponent?.src ||
        this?.control?.synopticComponent?.img ||
        (this.mode == "editor"
          ? "/static/common/images/missing-image.svg"
          : "")
      );
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlStyle() {
      let style = Object.assign({}, this.control.synopticComponent.style, {});
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return {
        ...style,
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation
        ) || 0}deg)`
      };
    },
    style() {
      let style = {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
      if (!this.src) {
        style.display = "none";
      }
      return style;
    }
  },
  created: function() {
    this.$emit("hasContent", true);
  }
};
</script>

<style scoped>
/* status icon component */
img.synoptic-image {
  padding: 0;
  margin: 0;
  border: 0;
}
</style>

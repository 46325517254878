<template>
  <div :style="style">
    <IframeWrapper
      v-if="!refreshing"
      src="/static/common/pages/synoptic-chart.html"
      :props="{
        widgetOptions,
        dataList: fullDataList,
        data_value_format_types
      }"
      v-on="listeners"
      @mousemove="updateTooltipPosition"
      @mouseleave="hideTooltip"
      @highlight="showTooltip"
    />
  </div>
</template>

<script>
import SynopticEquipmentDataControlBase from "@/components/synoptic/synoptic-equipment-data-control-base";
import IframeWrapper from "@/components/synoptic/iframe-wrapper";

import { defSerie } from "@/components/control-sidebar/property-editors/detail-form-chart.vue";
import omit from "lodash/omit";

export default {
  name: "SynopticChart",
  components: { IframeWrapper },
  extends: SynopticEquipmentDataControlBase,
  props: {
    zoom: {
      default: 1
    }
  },
  data() {
    return {
      refreshing: false,
      dataset: [],
      maximumValues: 100,
      minimumValues: 3
    };
  },
  computed: {
    chartOptions() {
      return this.control.synopticComponent.options?.chartOptions || null;
    },
    widgetOptions() {
      let options = JSON.parse(JSON.stringify(this?.chartOptions));
      if (options) {
        options.xAxis = options.xAxis || {};
        options.yAxis = options.yAxis || {};
        options.xAxis.show = this.dataset.length > 0;
        options.yAxis.show = this.dataset.length > 0;
        options.height = "auto";

        if (!this.dataset.length) {
          options.title = {
            show: true,
            textStyle: {
              color: "#bcbcbc"
            },
            text: this.$t("no_data_found"),
            left: "center",
            top: "center"
          };
        } else {
          options.series = this.dataset;
        }
      }
      return options;
    },
    ids() {
      return (this.control.synopticComponent.options.data || []).map(
        (d) => d.data_id
      );
    },
    fullDataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    dataList() {
      let dataList = this.fullDataList.filter(
        ({ id }) => this.ids.indexOf(id) >= 0
      );
      let entries = this.$store.getters["history/entries"] || {};
      return this.mode == "editor"
        ? dataList.map((data) => {
            let item = JSON.parse(JSON.stringify(data));
            item.history = (entries || {})[item.id] || null;
            return item;
          })
        : dataList;
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    },
    busy() {
      return (
        (this.mode == "editor" &&
          (this.$store.getters["history/pending"] || []).length > 0) ||
        false
      );
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    data_value_format_types() {
      return this.$root.config.references.data_value_format_types;
    },
    listeners() {
      return omit(this.$listeners, "dataChange", "hasContent");
    }
  },
  watch: {
    busy(n) {
      if (!n) {
        this.setupDataset();
      }
    },
    dataList: {
      deep: true,
      handler(val) {
        if (val && (this.mode == "editor" || !this.dataset.length)) {
          this.setupDataset();
        }
      }
    }
  },
  methods: {
    setupDataset() {
      this.refreshing = true;
      let dataset = [];
      this.ids.forEach((id, ix) => {
        let data = this.dataList.find((data) => data.id == id);
        if (!data) return;

        let samples;
        if (this.mode == "editor") {
          samples = data?.history?.samples ?? [];
        } else if (this.chartOptions.trailingValue && data?.current_value) {
          samples = [
            { date_time: moment().format(), value: data.current_value.value }
          ];
        } else {
          samples = [];
        }

        // if (!samples.length) return;

        let cfg = this.control.synopticComponent.options.data[ix];
        if (!cfg) return;

        cfg = {
          ...defSerie(cfg.chartOptions.type),
          ...(cfg.chartOptions || {})
        };
        if (!("enabled" in cfg.itemStyle)) {
          cfg.itemStyle.enabled = true;
        }
        if (!cfg.itemStyle.enabled) return;
        if (!cfg.name) {
          cfg.name = data.name;
        }

        // boolean type default treatment
        if (cfg.lineStyle) {
          switch (cfg.lineStyle.waveForm) {
            case "square":
              cfg.step = "end";
              cfg.smooth = false;
              break;
            case "triangle":
              cfg.step = false;
              cfg.smooth = false;
              break;
            case "sin":
              cfg.step = false;
              cfg.smooth = true;
              break;
          }
        }
        cfg.data = this.validateAndEvaluate(samples, cfg).map((sample) => [
          sample.date_time,
          parseFloat(sample.value)
        ]);
        dataset.push(cfg);
      });

      this.$set(this, "dataset", dataset);
      this.refreshing = false;

      this.setupRefreshTimer();
    },
    validateAndEvaluate(samples, config) {
      const filterSamples = () => {
        samples = samples.filter((sample) => {
          return this.$utils.isTrue(config.validation, {
            $value: sample.value
          });
        });
      };

      if (config.validation && config.validationReadValue) {
        filterSamples();
      }

      if (config.itemStyle?.expression) {
        samples = samples.map((sample) => {
          let value = this.$utils.eval(config.itemStyle?.expression, {
            $value: parseFloat(sample.value)
          });
          if (typeof value != "number" || isNaN(value)) value = sample.value;
          return { ...sample, value };
        });
      }

      if (config.validation && !config.validationReadValue) {
        filterSamples();
      }

      return samples;
    },
    refresh() {
      // updates series with current values
      this.dataset.forEach((series, index) => {
        const dataId = this.control.synopticComponent.options.data[index]
          .data_id;
        const data = this.dataList.find(({ id }) => id == dataId);

        if (data.current_value) {
          let date_time = moment().format();
          // prevent ocasional pushes of values with the same date_time
          if (series.data.at(-1)?.[0] != date_time) {
            let values = this.validateAndEvaluate(
              [{ date_time, value: parseFloat(data.current_value.value) }],
              series
            );
            // if no value was returned (it didn't pass validation)
            // duplicate previous value if available
            if (values.length == 0 && series.data.at(-1)) {
              series.data.push([date_time, series.data.at(-1)[1]]);
            } else if (values.length) {
              series.data.push([date_time, values[0].value]);
            }
          }
        }

        // check if first value is older than timeWindow
        // and there is at least the <minimum> amount of values
        // OR
        // there is more than the <maximum> amount
        if (
          (series.data.length >= this.minimumValues &&
            moment(series.data.at(-1)[0])
              .subtract(this.chartOptions.timeWindow, "seconds")
              .isAfter(series.data.at(0)[0])) ||
          series.data.length > this.maximumValues
        ) {
          series.data.shift();
        }
      });
    },
    setupRefreshTimer() {
      if (!this.dataset.length || this.mode != "viewer" || this._refreshTimer)
        return;

      this._refreshTimer = setInterval(
        this.refresh.bind(this),
        this.chartOptions.refreshInterval * 1000
      );
    },
    dataChange() {
      let title = this.control.synopticComponent.hint || this.control.title;
      this.$emit("dataChange", title);
    },
    updateTooltipPosition(e) {
      let rect = this.$el.getBoundingClientRect();
      let normalizedPos = {
        x: e.zrX + rect.left * this.zoom,
        y: e.zrY + rect.top * this.zoom
      };

      let tooltip = this.getTooltip();
      let { width, height } = tooltip.getBoundingClientRect();

      if (width && normalizedPos.x + width > window.innerWidth) {
        tooltip.style.right = window.innerWidth - normalizedPos.x - 10 + "px";
        tooltip.style.left = "";
      } else {
        tooltip.style.left = normalizedPos.x + 20 + "px";
        tooltip.style.right = "";
      }

      if (height && normalizedPos.y + height > window.innerHeight) {
        tooltip.style.bottom = window.innerHeight - normalizedPos.y + "px";
        tooltip.style.top = "";
      } else {
        tooltip.style.top = normalizedPos.y + 20 + "px";
        tooltip.style.bottom = "";
      }
    },
    showTooltip({ batch }) {
      let series = batch.map((series) => {
        let data = this.dataset[series.seriesIndex];
        return {
          value: data.data[series.dataIndex],
          color: data.itemStyle.color,
          seriesName: data.name
        };
      });

      let tooltip = this.getTooltip();
      tooltip.innerHTML = this.getTooltipContent(series);
      tooltip.style.setProperty("--font-size", 14 * this.zoom + "px");
      tooltip.style.display = "block";
    },
    hideTooltip() {
      this.getTooltip().style.display = "none";
    },
    getTooltipContent(params) {
      let content = "";
      params.forEach((series) => {
        if (!content) {
          content += moment(series.value[0]).format("LTS - ll");
        }

        content += `
            <br>
            <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
              series.color
            };"></span>
            ${series.seriesName.slice(0, 30) +
              (series.seriesName.length > 30 ? "..." : "")}: ${series.value[1]}
          `;
      });
      return content;
    },
    getTooltip() {
      if (
        !this._tooltipElement ||
        !document.body.contains(this._tooltipElement)
      ) {
        let tooltip = document.querySelector(".synoptic-chart-tooltip");
        if (!tooltip) {
          tooltip = document.createElement("div");
          tooltip.classList.add("synoptic-chart-tooltip");
          document.body.appendChild(tooltip);
        }
        this._tooltipElement = tooltip;
      }
      return this._tooltipElement;
    }
  },
  mounted() {
    this.$emit("hasContent", true);
    this.setupDataset();
    if (this.mode == "viewer")
      window.addEventListener("scroll", this.hideTooltip);
  },
  beforeDestroy() {
    clearInterval(this._refreshTimer);
    window.removeEventListener("scroll", this.hideTooltip);
  }
};
</script>

<style>
.synoptic-chart-tooltip {
  --font-size: 14px; /* will be changed according to zoom */
  position: fixed;
  display: none;
  border-style: solid;
  white-space: nowrap;
  z-index: 9999999;
  transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  background-color: rgba(50, 50, 50, 0.7);
  border-width: 0px;
  border-color: rgb(51, 51, 51);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font: clamp(1rem, var(--font-size), 1.5rem) / 21px "Microsoft YaHei";
  padding: 5px;
  pointer-events: none;
}
</style>

<template>
  <div :style="style" class="custom-control" id="customControl">
    <iframe
      :id="$utils.uuid()"
      :src="src"
      ref="iframe"
      frameborder="0"
      style="overflow:hidden;"
      @load.stop.prevent="onLoad($event)"
    ></iframe>
  </div>
</template>
<script>
import SynopticEquipmentDataControlBase from "@/components/synoptic/synoptic-equipment-data-control-base";

export default {
  name: "SynopticCustomControl",
  extends: SynopticEquipmentDataControlBase,
  props: {
    // value: {
    //   type: Object,
    //   required: false,
    //   default: () => null
    // }
  },
  data: () => ({
    loaded: false,
    value: { id: "123", current_value: { value: 0 } }
  }),
  computed: {
    src() {
      return this?.control?.synopticComponent?.options?.src || "";
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n && this.$refs.iframe && this.loaded) {
          this.notify(this.$refs.iframe, this.value);
        }
      },
      deep: true
    }
  },
  methods: {
    notify(target, data) {
      target.contentWindow.postMessage({
        hi_msg: "custom",
        value: JSON.parse(JSON.stringify(data))
      });
    },
    onLoad($event) {
      this.loaded = true;
      // value changes simulation
      setInterval(
        () => {
          this.value.current_value.value = Math.random(1000);
        },
        5000,
        this
      );
      this.notify($event.target, this.value);
    }
  },
  mounted() {
    this.$emit("hasContent", true);
  },
  beforeDestroy() {}
};
</script>

<style>
.custom-control {
  position: relative;
}

.custom-control > iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>

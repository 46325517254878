<template>
  <div class="state-list-form">
    <label
      class="clicable no-select state-list-form-title"
      @click="collapsed = !collapsed"
    >
      {{ $t(title) }}
      <span title="" class="btn btn-xs pull-right toolbar-button">
        <i
          :class="collapsed ? 'fa fa-chevron-right' : 'fa fa-chevron-down'"
        ></i>
      </span>
    </label>
    <div v-if="!collapsed" @mousewheel.stop>
      <div class="form-group form-group-sm source-selector">
        <div class="input-group">
          <div class="input-group-addon">{{ $t("data_source") }}</div>
          <select
            v-model="sourceType"
            class="form-control"
            data-testid="source"
          >
            <option
              v-bind:value="item.type"
              v-for="(item, ix) in dataSources"
              v-bind:key="ix"
            >
              {{ $tc(item.label) }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="form-group form-group-sm form-group-extension"
        v-if="dataSourceType == 'global'"
      >
        <select
          v-model="sourceId"
          class="form-control"
          ref="globalList"
          data-testid="source-id"
        >
          <option
            v-bind:value="item.id"
            v-for="(item, ix) in globalLists"
            v-bind:key="ix"
          >
            {{ $tc(item.name) }}
          </option>
        </select>
      </div>
      <div v-if="items">
        <div class="clicable state-list-header" @click.stop.prevent="toggleAll">
          {{ $tc("options", 1) }}
          <div class="pull-right" style="padding-right: 5px">
            <span
              v-if="dataSourceType == 'local' && items && items.length"
              class="btn btn-xs toolbar-button"
              @click.stop.prevent="resetItems"
              :title="$tc('hints.remove_all', 1)"
            >
              <i class="fa fa-trash" />
            </span>
            <span class="btn btn-xs toolbar-button">
              <i
                :class="anyEnabled ? 'fa fa-angle-down' : 'fa fa-angle-right'"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="state-list" v-if="items">
        <div
          class="state-image"
          v-for="(item, index) in items"
          :key="index"
          data-testid="state-image"
        >
          <div class="row">
            <div class="form-group form-group-sm form-group-min col-xs-12">
              <div class="clicable" @click.stop.prevent="toggle(index, $event)">
                <label
                  :for="`editor-label-${index}`"
                  class="no-select clicable"
                  :class="{
                    disabled: dataSourceType != 'local',
                    'text-primary': isDefault(item)
                  }"
                  :title="$t('hints.set_as_default')"
                >
                  <i
                    v-if="hasDefault"
                    @click.prevent.stop="
                      hasDefault ? setAsDefault(item) : toggle(index, $event)
                    "
                    :class="
                      isDefault(item)
                        ? 'fa fa-check-circle-o'
                        : 'fa fa-circle-o'
                    "
                  ></i>
                  <span>
                    <span v-if="isEnabled(index)">
                      {{ index + 1 }}.{{ $t("text") }}
                    </span>
                    <span v-else>
                      {{ item.label || $t("text") }}
                    </span>
                  </span>
                </label>
                <div class="pull-right">
                  <span
                    class="btn btn-xs toolbar-button"
                    v-if="dataSourceType == 'local'"
                    @click="removeAt(index)"
                    :title="$t('synoptic.remove_image')"
                  >
                    <i class="fa fa-trash" />
                  </span>
                  <span
                    class="btn btn-xs toolbar-button"
                    :title="$t('expand_collapse_panel')"
                  >
                    <i
                      :class="
                        isEnabled(index)
                          ? 'fa fa-angle-down'
                          : 'fa fa-angle-right'
                      "
                    />
                  </span>
                </div>
              </div>
              <div class="input-group input-group-sm">
                <template v-if="floatPanel">
                  <div
                    class="input-group-addon no-padding"
                    v-if="isEnabled(index)"
                  >
                    <div
                      style="
                        position: relative;
                        clear: both;
                        white-space: normal;
                      "
                    >
                      <ColorPicker
                        :value="color(item)"
                        @input="color(item, $event)"
                        icon="background"
                        data-testid="background-color"
                        :disabled="!hasColor"
                      />
                    </div>
                  </div>
                  <input
                    v-if="isEnabled(index)"
                    type="text"
                    class="form-control"
                    v-model="item.label"
                    :id="`editor-label-${index}`"
                    :ref="`editor-label-${index}`"
                    :placeholder="$t('synoptic.insert_label')"
                    :disabled="dataSourceType != 'local'"
                    data-testid="input-label"
                  />
                </template>
                <template v-else>
                  <input
                    v-if="isEnabled(index)"
                    type="text"
                    class="form-control"
                    v-model="item.label"
                    :id="`editor-label-${index}`"
                    :ref="`editor-label-${index}`"
                    :placeholder="$t('synoptic.insert_label')"
                    :disabled="dataSourceType != 'local'"
                    data-testid="input-label"
                  />
                  <div
                    class="input-group-addon no-padding"
                    v-if="isEnabled(index)"
                  >
                    <ColorPicker
                      :value="color(item)"
                      @input="color(item, $event)"
                      v-bind:pickerStyle="{left: '-191px'}"
                      icon="background"
                      data-testid="background-color"
                      :disabled="!hasColor"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="row" v-if="isEnabled(index) && !isDefault(item.state)">
            <div class="form-group form-group-sm form-group-min col-xs-12">
              <label :for="`editor-state-${index}`">{{ $t("value") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="item.state"
                :id="`editor-state-${index}`"
                :placeholder="$t('synoptic.data_value')"
                :disabled="dataSourceType != 'local'"
              />
            </div>
          </div>
          <div class="row" v-if="hasImage && isEnabled(index)">
            <ImageSelection
              class="col-xs-12"
              :labelAttrs="{for: `editor-img-${index}`}"
              :inputAttrs="{id: `editor-img-${index}`}"
              :value="image(item)"
              @input="image(item, $event)"
              @imageRemoved="image(item, '')"
              data-testid="image"
            />
          </div>
        </div>
        <div class="state-list-footer">
          <button
            type="button"
            class="btn btn-xs btn-primary"
            @click="add"
            v-if="dataSourceType == 'local'"
            data-testid="new-state"
          >
            {{ $t("synoptic.new_text") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/editor/color-picker.vue";
import ImageSelection from "@/components/synoptic/property-editor/controls/image-selection.vue";
export default {
  name: "DataStateListForm",
  components: {
    ColorPicker,
    ImageSelection
  },
  props: {
    title: {
      type: String,
      default: "synoptic.text_list",
      required: false
    },
    equipmentData: {
      type: Object,
      required: false,
      default: () => null
    },
    value: {
      type: Object,
      required: false,
      default: () => ({
        dataSource: {
          type: "local",
          id: ""
        },
        default: "",
        items: []
      })
    },
    hasDefault: {
      type: Boolean,
      default: true,
      required: false
    },
    hasColor: {
      type: Boolean,
      default: true,
      required: false
    },
    hasImage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      collapsed: false,
      enabled: {},
      dataSourceType: "local",
      dataSourceId: "",
      default: "",
      items: [],
      floatPanel: false
    };
  },
  computed: {
    dataSources() {
      let lst = [{type: "local", label: "control"}];
      if (this.globalLists?.length) {
        if (this.equipmentData && this.equipmentData.text_list) {
          lst.push({type: "data", label: "data"});
        }
        lst.push({type: "global", label: "global"});
      }
      return lst;
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    },
    payload: {
      set(value) {
        if (value) {
          this.dataSourceType = value?.dataSource?.type || "local";
          this.dataSourceId = value?.dataSource?.id || "";
          this.default = value.default || "";
          this.$set(
            this,
            "items",
            JSON.parse(JSON.stringify(value.items || []))
          );
        }
      },
      get() {
        return {
          dataSource: {
            type: this.dataSourceType,
            id: this.dataSourceId
          },
          default: this.default,
          items: [...(this.items || [])]
        };
      }
    },
    isDirty() {
      return JSON.stringify(this.payload) != JSON.stringify(this.value);
    },
    sourceType: {
      set(value) {
        if (value == "global") {
          if (!this.dataSourceId) this.dataSourceId = this.globalLists[0].id;
          // this.$nextTick(() => {
          //   this.$refs.globalList.focus();
          // });
        } else if (value == "data") {
          if (this?.equipmentData?.text_list?.id) {
            this.dataSourceId = this?.equipmentData?.text_list?.id || o;
          }
        } else {
          if (!this.dataSourceId) this.dataSourceId = "";
        }
        this.dataSourceType = value;
        if (this.isDirty) {
          this.buildItems();
        }
      },
      get() {
        return this.dataSourceType;
      }
    },
    sourceId: {
      set(value) {
        this.dataSourceId = value;
        if (this.isDirty) {
          this.buildItems();
        }
      },
      get() {
        return this.dataSourceId;
      }
    },
    anyEnabled() {
      return (this.items || []).some(({state}) => this.enabled[state]);
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n) {
          this.payload = n;
        }
      },
      deep: true,
      immediate: true
    },
    payload: {
      handler(n) {
        if (JSON.stringify(n) == JSON.stringify(this.value)) return;
        this.$emit("input", n);
      },
      deep: true
    }
  },
  methods: {
    isDefault(item) {
      return item.state == this.default;
    },
    buildItems() {
      let lst = [];
      if (this.dataSourceType == "local") {
        lst = [...(this?.items || [])];
      } else {
        let id = this.dataSourceId || 0;
        if (id) {
          let entry = this.globalLists.find((i) => i.id == id) || null;
          // embedded items is not a list, but object
          let keys = Object.keys(entry?.items || {});
          // items
          let prv;
          keys.forEach((key) => {
            prv = (this.items || []).find(({state}) => state == key);
            lst.push({
              state: key,
              backgroundColor: prv?.backgroundColor || "transparent",
              img: prv?.img || "",
              label: entry.items[key]
            });
          });
          // default
          keys = Object.keys(entry?.default_item || {});
          if (keys.length) {
            this.default = keys[0];
          }
        }
      }
      (lst || []).forEach(({state}) => (this.enabled[state] = false));
      this.$set(this, "items", JSON.parse(JSON.stringify(lst)));
    },
    setAsDefault(item) {
      if (!this.hasDefault || !(this.dataSourceType === "local")) return;
      this.default = item.state;
    },
    commitChanges() {
      if (this.isDirty) {
        this.$emit("input", this.payload);
      }
    },
    removeAt(ix) {
      if (this.items[ix].state == this.default) {
        this.default = "";
      }
      this.items.splice(ix, 1);
      if (this.enabled[ix] !== undefined) {
        delete this.enabled[ix];
      }
    },
    add() {
      let lst = (this.items || []).filter(
        ({state}) => parseInt(state) == state
      );
      var next =
        (lst || [])
          .map((i) => i.state)
          .reduce(function (a, b) {
            return Math.max(parseInt(a), parseInt(b));
          }, 0) + 1;
      this.items.push({
        backgroundColor: "transparent",
        img: "",
        label: "",
        state: next
      });
      let el = `editor-label-${this.items.length - 1}`;
      this.$nextTick(() => {
        if (this.$refs[el]) this.$refs[el][0].focus();
      });
    },
    toggle(ix) {
      let lst = this.enabled || {};
      lst[ix] = lst[ix] === undefined ? false : !lst[ix];
      this.$set(this, "enabled", JSON.parse(JSON.stringify(lst)));
    },
    toggleAll() {
      let entry = {};
      this.items.forEach(({state}) => (entry[state] = !this.anyEnabled));
      this.$set(this, "enabled", entry);
    },
    isEnabled(ix) {
      if (!(ix in this.enabled)) {
        return true;
      }
      return this.enabled[ix];
    },
    resetItems() {
      if (this.dataSourceType != "local") return;
      this.$set(this, "items", []);
    },
    image(item, value) {
      if (!item) return "";
      let ix = (this.items || []).findIndex(({state}) => {
        return item.state == state;
      });
      if (ix > -1) {
        if (value !== undefined) {
          this.$set(this.items[ix], "img", value);
        }
        return this.items[ix].img;
      }
      return "";
    },
    color(item, value) {
      if (!item) return "";
      let ix = (this.items || []).findIndex(({state}) => {
        return item.state == state;
      });
      if (ix > -1) {
        if (value !== undefined) {
          this.$set(this.items[ix], "backgroundColor", value);
        }
        return this.items[ix].backgroundColor;
      }
      return "";
    }
  },
  mounted() {
    if (this.$el.closest(".float-panel")) {
      this.floatPanel = true;
    }
    if ("collapsed" in (this.$attrs || {})) {
      this.collapsed = true;
    }
    this.items = [...(this?.value?.items || [])];
    if (
      this?.value?.dataSource?.type == "data" &&
      this?.equipmentData?.text_list?.id
    ) {
      this.sourceType = "data";
      // this.buildItems();
    }
    this.buildItems();
  }
};
</script>

<style scoped>
i.fa {
  min-width: 16px;
}
.state-list-form {
  padding: 2px;
}
.state-list-header {
  padding: 3px 5px 5px 5px;
  margin: -5px -1px -1px -1px;
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  background: whitesmoke;
  border-radius: 2px 2px 0 0;
}
.state-list-footer {
  text-align: center;
  margin: 10px 0 50px 0; /* bottom padding is important so it can be visible on scroll */
}

.state-list {
  margin: -1px;
  max-height: 35dvh;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #dcdcdc;
  padding: 0 2px 10px 2px;
  border-radius: 0 0 2px 2px;
}

.state-list::-webkit-scrollbar {
  width: 8px;
}

.state-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.state-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.state-list-form > .state-list-form-title {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 12pt;
  font-weight: 400;
}

.toolbar-button {
  margin-right: -4px;
}

.source-selector {
  padding: 0 6px 7px 6px;
  margin: 0 -7px;
}

.state-image {
  margin-top: 1rem;
}

.state-image:not(:last-of-type) {
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(195, 195, 195);
}

.form-group-min {
  margin-bottom: 0px;
}

.form-group-extension {
  margin: -8px -1px 7px -1px;
  text-align: left;
}

.form-group-extension > select {
  padding: 0;
}
label.disabled {
  opacity: 0.7;
}

label.disabled:hover {
  cursor: not-allowed;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>

<template>
  <div>
    <div
      data-testid="control"
      draggable="true"
      class="control"
      v-for="(item, ix) in items"
      :key="ix"
      :title="`${item.template.title}\n${$t('synoptic.controls.' + item.name)}`"
      v-on:click.stop.prevent="onClick(item)"
      @dragstart="handleDragStart($event, item)"
      @dragend="handleDragEnd($event)"
      @dragover="handleDragOver($event)"
    >
      <img
        :src="item.thumbnail"
        :alt="item.template.title || item.template.name"
      />
    </div>
  </div>
</template>

<script>
import controls from "@/assets/dashboard/controls.json";

export default {
  name: "ControlGalleryPanel",
  data() {
    return {};
  },
  computed: {
    items() {
      return (controls || []).filter((item) => {
        return "show_in_gallery" in item ? item.show_in_gallery : true;
      });
    }
  },
  methods: {
    onClick(item) {
      this.$store
        .dispatch(
          "synoptic/addControl",
          JSON.parse(JSON.stringify(item.template))
        )
        .then(() => {
          document
            .querySelector(".keyboard-container")
            .focus({ preventScroll: true });
        });
    },
    handleDragStart(e, item) {
      e.target.style.opacity = "0.4";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", JSON.stringify(item.template));
    },
    handleDragEnd(e) {
      e.target.style.opacity = "1";
    },
    handleDragOver(e) {
      e.preventDefault();
    }
  }
};
</script>

<style scoped>
.control {
  display: inline-block;
  margin: 5px;
  max-width: 46px;
  border-radius: 5px;
  overflow: hidden;
}

.control:hover {
  cursor: pointer;
  opacity: 0.7;
}

.control > img {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
</style>

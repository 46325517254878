<template>
  <div class="form-group form-group-sm" v-if="equipmentData">
    <div
      class="small clicable"
      style="padding: 0px 5px 5px 2px;color: #999;"
      v-if="showConnector && connector"
      :title="`${connector.id} ${connector.name}`"
      @click.stop.prevent="showConnector = !showConnector"
    >
      <i class="fa fa-plug"></i> {{ connector.name }}
    </div>
    <div class="input-group">
      <div
        class="input-group-addon clicable"
        style="padding:0 4px; font-size:80%;color:#666;"
        :title="`${connector && connector.id} ${connector && connector.name}`"
        @click.stop.prevent="showConnector = !showConnector"
      >
        <i class="fa fa-plug" v-if="!showConnector"></i>
        {{ $t("source") }}
      </div>
      <input
        type="text"
        class="form-control"
        disabled
        v-model="equipmentData.name"
      />
      <div
        class="input-group-addon btn"
        :class="simulation ? 'text-orange' : ''"
        v-on:click.stop.prevent="toggle()"
        :title="$t('simulation')"
      >
        <i class="fa fa-history hidden-xs"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataSimulationToggle",
  props: {
    equipmentData: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      showConnector: true
    };
  },
  computed: {
    simulation() {
      if (!this.equipmentData) return false;
      let entries = this.$store.getters["history/entries"] || {};
      return ((entries[this.equipmentData.id] || {})?.samples || []).length > 0;
    },
    connector() {
      return (this.$store.getters["dashboard/connectorList"] || []).find(
        ({ id }) => id == this.equipmentData.clp_id
      );
    }
  },
  methods: {
    toggle() {
      this.$store.dispatch("history/simulate", this.equipmentData.id);
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>

<template>
  <section>
    <TogglePanel
      :title="`${$tc('y_axle', 1)}`"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      persistent="toogle_chart_series_y_axle"
    >
      <div>
        <div class="form-group form-group-sm">
          <div>
            <label for="">{{ $t("scale") }}</label>
            <div
              class="pull-right clicable"
              @click.stop.prevent="yInverse = !yInverse"
            >
              <span>{{ $tc("titles.inverse", 2) }} </span>
              <i
                :class="yInverse ? 'fa fa-check-square-o' : 'fa fa-square-o'"
              ></i>
            </div>
          </div>
          <div class="input-group">
            <div class="inline-field" style="width: 33%" :title="$t('minimum')">
              <label class="inline-field-label">{{ $t("minimum") }}</label>
              <input
                type="text"
                class="form-control inner-field-input"
                @input="yAxleProp('min', $event.target.value)"
                :value="yAxleProp('min')"
              />
            </div>
            <div class="inline-field" style="width: 33%" :title="$t('maximum')">
              <label class="inline-field-label">{{ $t("maximum") }}</label>
              <input
                type="text"
                class="form-control inner-field-input"
                @input="yAxleProp('max', $event.target.value)"
                :value="yAxleProp('max')"
              />
            </div>
            <div
              class="inline-field"
              style="width: 34%"
              :title="$t('increment')"
            >
              <label class="inline-field-label">{{ $t("increment") }}</label>
              <input
                type="text"
                class="form-control inner-field-input"
                @input="yAxleProp('interval', $event.target.value)"
                :value="yAxleProp('interval')"
              />
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: -18px">
        <div class="form-group form-group-sm">
          <div class="input-group">
            <div class="input-group-addon">{{ $t("format") }}</div>
            <select class="form-control" v-model="yScaleType">
              <option value="value">{{ $t("value") }}</option>
              <option value="text_list">{{ $t("text_list") }}</option>
              <option value="expression">{{ $t("expression") }}</option>
            </select>
          </div>
        </div>
        <DataStateListForm
          v-if="yScaleType == 'text_list'"
          :hasDefault="false"
          :hasColor="false"
          v-model="yScaleTextList"
        />
        <div class="expression-box" v-if="yScaleType == 'expression'">
          <div
            class="clicable"
            @click.stop.prevent="collapseExpression = !collapseExpression"
          >
            <label class="clicable">{{ $t("expression") }} </label>
            <span class="pull-right">
              <i
                class="fa"
                :class="
                  collapseExpression
                    ? 'fa-caret-square-o-down'
                    : 'fa-caret-square-o-up'
                "
              ></i>
            </span>
          </div>
          <JSONPathPicker
            v-if="!collapseExpression"
            v-model="yScaleExpression"
            :entry="yScaleExpressionEntry"
            style="margin-bottom: 43px"
          />
        </div>
      </div>
    </TogglePanel>
    <ChartTooltipForm v-model="chartTooltipConfig" v-if="chartTooltipConfig" />
    <TogglePanel
      :title="`${$tc('data_series', 1)}`"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      persistent="toogle_chart_series_data_series"
    >
      <slot name="before"></slot>
      <div class="title data-selection-box">
        <div
          v-if="!dataSelector"
          class="clicable data-toggle"
          @click.stop.prevent="dataSelector = !dataSelector"
        >
          <i class="fa fa-caret-square-o-up" v-if="dataSelector"></i>
          <i class="fa fa-caret-square-o-down" v-else></i>
        </div>
        <div v-if="dataSelector">
          <ControlDataSelector
            v-model="dataId"
            :parser="uniqueDataList"
            :connectorId="connectorId"
            @connectorChanged="connectorId = $event"
            :dataListParser="dataListParser"
            :allowedTypes="['bool', 'float', 'int', 'string']"
            label="data_selector"
          >
            <template #extra_label>
              <div
                v-if="dataSelector"
                class="clicable data-toggle"
                @click.stop.prevent="dataSelector = !dataSelector"
              >
                <i class="fa fa-caret-square-o-up" v-if="dataSelector"></i>
                <i class="fa fa-caret-square-o-down" v-else></i>
              </div>
            </template>
          </ControlDataSelector>
          <div class="text-center" style="margin-bottom: 10px">
            <span
              class="btn btn-xs btn-primary"
              :class="dataId ? '' : 'disabled'"
              @click.stop.prevent="onAddData"
              style="margin: 0 10px 0 0"
            >
              {{ $tc("add_serie", 1) }}
            </span>
            <span class="btn btn-xs btn-default" @click.stop.prevent="onImport">
              {{ $t("titles.add_all_data") }}
            </span>
          </div>
        </div>
        <label
          v-else
          class="clicable text-primary no-select"
          @click.stop.prevent="dataSelector = !dataSelector"
        >
          {{ $t("add_serie") }}
        </label>
      </div>
      <draggable
        v-model="dataList"
        class="item"
        handle=".handle"
        :options="{disabled: hasOpenedSeries}"
      >
        <template v-for="(item, ix) in dataList">
          <ChartSerieForm
            :value="item"
            :index="ix"
            :key="ix"
            :equipmentData="getEquipmentDataAt(ix)"
            :changeType="changeType"
            :checkable="checkable"
            :types="types"
            :symbol="symbol"
            :showApplyExpresson="showApplyExpresson"
            :showInterpolation="showInterpolation"
            @removeData="onRemoveData"
            @changeType="onChangeType(ix, $event)"
            @check="onItemCheck(ix, $event)"
            @enable="onItemEnable(ix, $event)"
            @open="setSerieAsOpen(ix, true)"
            @close="setSerieAsOpen(ix, false)"
            class="handle"
          />
        </template>
      </draggable>
      <slot name="after"></slot>
    </TogglePanel>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ChartSerieForm from "@/components/control-sidebar/property-editors/chart-serie-form.vue";
import DataStateListForm from "@/components/control-sidebar/property-editors/data-state-list-form.vue";
import ChartTooltipForm from "@/components/control-sidebar/property-editors/chart-tooltip-form.vue";
import Chart from "@/assets/dashboard/chart.json";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import isEqual from "lodash/isEqual";

const defSerie = (type) => {
  return JSON.parse(JSON.stringify(Chart.seriesOptions[type || "line"]));
};

export {defSerie};

export default {
  name: "ChartSeriesForm",
  components: {
    TogglePanel,
    ControlDataSelector,
    ChartSerieForm,
    DataStateListForm,
    JSONPathPicker,
    draggable,
    ChartTooltipForm
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    checkable: {
      type: Boolean,
      required: false,
      default: true
    },
    addAll: {
      type: Boolean,
      required: false,
      default: true
    },
    dataListParser: {
      type: Function,
      required: false,
      default: (lst) => lst
    },
    types: {
      type: Array,
      required: false,
      default: () => ["line", "bar", "pie"]
    },
    listName: {
      type: String,
      default: "data",
      required: false
    },
    symbol: {
      type: Boolean,
      required: false,
      default: true
    },
    changeType: {
      type: Boolean,
      required: false,
      default: true
    },
    showApplyExpresson: {
      type: Boolean,
      requried: false
    },
    showInterpolation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      curConnectorId: undefined,
      curDataId: undefined,
      panelOptions: null,
      dataSelector: true,
      opened: {},
      collapseExpression: false
    };
  },
  computed: {
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    dataList: {
      set(value) {
        this.panelOptions = this.panelOptions || {};
        this.$set(this.panelOptions, this.listName, value);
      },
      get() {
        return (this?.panelOptions || {})[this.listName] || [];
      }
    },
    dataIds() {
      return this.dataList.map(({data_id}) => data_id);
    },
    chartOptions() {
      return this.panelOptions?.chartOptions || null;
    },
    dataId: {
      set(vlr) {
        let digits = (vlr + "").match(/\d+/g);
        if (digits) {
          vlr = parseInt(digits.join(""));
        } else {
          vlr = "";
        }
        this.curDataId = vlr || "";
      },
      get() {
        return this.curDataId || "";
      }
    },
    connectorId: {
      set(value) {
        this.curConnectorId = value;
      },
      get() {
        return this.curConnectorId || this.referenceConnectorId || undefined;
      }
    },
    referenceConnectorId() {
      return (
        (this.$store.getters["dashboard/screenRefMap"](this.screenId) || {})
          ?.conn1 || 0
      );
    },
    errors() {
      let entry = {
        cellStyle: "" // TODO: implement error parser
      };
      return entry;
    },
    equipmentDataList() {
      return (this.$store.getters["dashboard/dataList"] || []).filter(
        (data) => data?.clp_id == this.connectorId
      );
    },
    hasOpenedSeries() {
      for (var i in this.opened) {
        if (this.opened[i]) return true;
      }
      return false;
    },
    yScaleType: {
      set(value) {
        if (!this?.panelOptions?.chartOptions) {
          // history panel backward compatibility
          this.$set(this.panelOptions, "chartOptions", {yAxis: {}});
        }
        var yAxis = JSON.parse(
          JSON.stringify(this?.panelOptions?.chartOptions?.yAxis || {})
        );
        yAxis.yScale = yAxis.yScale || {};
        yAxis.yScale.type = value;
        if (value == "text_list") {
          yAxis.yScale.expression = "";
        } else if (value == "expression") {
          yAxis.yScale.textList = null;
        } else {
          yAxis.yScale.expression = "";
          yAxis.yScale.textList = null;
        }
        this.$set(this.panelOptions.chartOptions, "yAxis", yAxis);
      },
      get() {
        let yAxis = this?.panelOptions?.chartOptions?.yAxis || {};
        return yAxis?.yScale?.type || "value";
      }
    },
    yScaleTextList: {
      set(value) {
        var yScale = JSON.parse(
          JSON.stringify(this?.panelOptions?.chartOptions?.yAxis?.yScale || {})
        );
        yScale.textList = value;
        this.$set(this.panelOptions.chartOptions.yAxis, "yScale", yScale);
      },
      get() {
        let yAxis = this?.panelOptions?.chartOptions?.yAxis || {};
        return yAxis?.yScale?.textList || null;
      }
    },
    yScaleExpression: {
      set(value) {
        var yScale = JSON.parse(
          JSON.stringify(this?.panelOptions?.chartOptions?.yAxis?.yScale || {})
        );
        yScale.expression = value;
        this.$set(this.panelOptions.chartOptions.yAxis, "yScale", yScale);
      },
      get() {
        let yAxis = this?.panelOptions?.chartOptions?.yAxis || {};
        return yAxis?.yScale?.expression || null;
      }
    },
    yScaleExpressionEntry() {
      return {
        minimum: this.yAxleProp("min"),
        maximum: this.yAxleProp("max"),
        interval: this.yAxleProp("interval"),
        value: ""
      };
    },
    chartTooltipConfig: {
      set(value) {
        if (!this.panelOptions.chartOptions) return;
        this.$set(this.panelOptions.chartOptions, "tooltip", value);
      },
      get() {
        return this?.chartOptions?.tooltip || {};
      }
    },
    yInverse: {
      set(value) {
        this.yAxleProp("inverse", value);
      },
      get() {
        let vlr = this.yAxleProp("inverse");
        return vlr === "auto" || vlr === undefined ? false : vlr;
      }
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(n, o) {
        if (!isEqual(n, this.panelOptions)) {
          let panelOptions = JSON.parse(JSON.stringify(n));
          if (!o && n) {
            let missing = [];
            ((panelOptions || {})[this.listName] || []).forEach((data, ix) => {
              if (!data.chartOptions && data.data_id) {
                missing.push({id: data.data_id, ix: ix});
              }
            });
            if (missing.length) {
              this.panelOptions = panelOptions;
              missing.reverse().forEach((i) => {
                this.onRemoveData(i.ix);
              });
              missing.forEach((i) => {
                this.addData(i.id);
              });
              return;
            }
          }
          this.$set(this, "panelOptions", panelOptions);
        }
      }
    },
    panelOptions: {
      deep: true,
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit("input", val);
        }
      }
    }
  },
  methods: {
    yAxleProp(name, value) {
      let origin = this?.panelOptions?.chartOptions;
      // let origin = this?.chartOptions || this?.panelOptions?.chartOptions;
      if (!origin) {
        // history panel backward compatibility
        this.$set(this.panelOptions, "chartOptions", {});
        origin = this.panelOptions.chartOptions;
      }
      let yAxis = JSON.parse(JSON.stringify(origin?.yAxis || {}));
      var vlr = "auto";
      if (value === undefined) {
        if (name in yAxis) {
          vlr = yAxis[name];
        }
      } else {
        if (value === "") {
          vlr = "auto";
        } else if (value === true || value === false) {
          vlr = value;
        } else {
          vlr = isNaN(Number(value)) ? 0 : parseFloat(value);
        }
        yAxis[name] = vlr;
        this.$set(origin, "yAxis", yAxis);
      }
      return vlr;
    },
    addData(dataId) {
      if (!dataId) return;
      // TODO: it seems that data selector is not adding to the global data list after connector changes
      let data = (this.equipmentDataList || []).find((i) => i.id == dataId);
      let chartOptions = defSerie("line");
      chartOptions.name = (data && data.name) || "";
      chartOptions.identity_embedded_app =
        (data && data.identity_embedded_app) || "";
      if ((data?.memory_type?.name || "").match(/(bool|coil)/gi) != null) {
        chartOptions.lineStyle.waveForm = "square";
      }
      chartOptions.itemStyle.color = Chart.colors[this.dataList.length || 0];
      this.dataList.push({
        data_id: dataId,
        chartOptions: chartOptions,
        checked: true,
        enabled: true
      });
    },
    onAddData() {
      this.addData(this.curDataId);
      if (this.curDataId && !this.hasSimulatedSamples(this.curDataId)) {
        this.$store.dispatch("history/simulate", this.curDataId);
      }
    },
    onImport() {
      this.dataListParser(this.equipmentDataList).forEach(({id}) => {
        this.addData(id);
      });
    },
    onRemoveData(ix) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        this.dataList.splice(ix, 1);
      }
    },
    onChangeType(ix, type) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        let oldChartOptions = this.dataList[ix].chartOptions;
        let newChartOptions = defSerie(type);
        if (oldChartOptions.type != newChartOptions.type) {
          newChartOptions.name = oldChartOptions.name;
          newChartOptions.identity_embedded_app =
            oldChartOptions.identity_embedded_app;
          newChartOptions.itemStyle.color = oldChartOptions.itemStyle.color;
          newChartOptions.itemStyle.expression =
            oldChartOptions.itemStyle.expression;
          this.$set(this.dataList[ix], "chartOptions", newChartOptions);
        }
      }
    },
    onItemCheck(ix, value) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        this.$set(this.dataList[ix], "checked", value);
      }
    },
    onItemEnable(ix, value) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        // this.$set(this.dataList[ix].chartOptions.itemStyle, "enabled", value);
        // this.$set(this.dataList[ix], "enabled", value);
        this.dataList[ix].chartOptions.itemStyle.enabled = value;
        this.dataList[ix].enabled = value;
      }
    },
    getEquipmentDataAt(ix) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        let id = this.dataList[ix].data_id;
        return (
          (this.$store.getters["dashboard/dataList"] || []).find(
            (data) => data.id == id
          ) || null
        );
      }
      return null;
    },
    uniqueDataList(items) {
      let lst = this.dataListParser(items);
      return lst.filter(({id}) => this.dataIds.indexOf(id) == -1);
    },
    setSerieAsOpen(ix, value) {
      this.$set(this.opened, ix, value);
    },
    hasSimulatedSamples(dataId) {
      let entries = this.$store.getters["history/entries"] || {};
      return ((entries[dataId] || {})?.samples || []).length > 0;
    }
  }
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}

.tab-content {
  background: white;
}

.inner-panel {
  padding-top: 10px;
}

.inner-panel > * {
  margin-bottom: 10px;
}

.clicable:hover {
  background-color: transparent;
  cursor: pointer;
  opacity: 0.8;
}

.data-selection-box {
  position: relative;
  /* background-color: whitesmoke; */
  padding: 5px;
  margin: 5px -5px 5px -5px;
}

.title {
  font-size: 10pt;
}

.data-toggle-link {
  padding: 2px;
}

.data-toggle {
  float: right;
}

.content-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-field {
  position: relative;
  display: inline-block;
}

.inline-field > input {
  z-index: 0;
  background-color: transparent;
  text-align: center;
  padding: 8px 0 0 0;
}

.inline-field > label {
  position: absolute;
  top: -2px;
  left: 3px;
  z-index: 1;
  font-size: 8pt;
  font-weight: normal;
  margin: 0;
  color: #777;
}

.expression-box {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 2px 6px;
}
</style>

<template>
  <span
    v-if="isValid"
    class="synoptic-tooltip"
    :class="position"
    :style="style"
  >
    <template v-if="mode == 'editor'">
      <Tooltip icon="fa fa-info" :title="$t('command_status')"></Tooltip>
    </template>
    <template v-else>
      <PendingCommandIndicator v-if="hasPendingCommand" :entry="entry" />
      <Tooltip
        v-else-if="value != '' && error"
        icon="fa fa-exclamation fa-blink fa-blink-slow text-danger"
        :title="$t(error)"
      ></Tooltip>
    </template>
  </span>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";
import PendingCommandIndicator from "@/components/data/pending-command-indicator.vue";
export default {
  name: "SynopticDataValueTip",
  components: {
    Tooltip,
    PendingCommandIndicator
  },
  props: {
    position: {
      type: String,
      required: false,
      default: () => "top-right"
    },
    value: {
      type: [String, Number],
      required: false,
      default: () => ""
    },
    error: {
      type: String,
      required: false,
      default: () => ""
    },
    entry: {
      type: Object,
      required: false,
      default: () => null
    },
    hasPendingCommand: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      positions: [
        "top-left",
        "top-right",
        "top-center",
        "middle-left",
        "middle-right",
        "middle-center",
        "bottom-left",
        "bottom-right",
        "bottom-center"
      ]
    };
  },
  computed: {
    mode() {
      //return this.$store.getters['dashboard/mode']||'viewer';
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    isValid() {
      return (
        this.positions.indexOf(this?.position || "none") >= 0 &&
        (this.mode == "editor" || this.entry)
      );
    },
    cls() {
      return [this.isValid ? this.position : "top-right"];
    },
    style() {
      return {
        "--text-size-adjust": this.$utils.iOS() ? "45%" : "90%",
        "--height": "14px"
      };
    }
  }
};
</script>

<style scoped>
.synoptic-tooltip {
  position: absolute;
  font-size: 12px;
  font-weight: inherit;
  z-index: auto;
  height: var(--height);
  -webkit-text-size-adjust: var(--text-size-adjust);
}

.top-left {
  top: 8px;
  left: 2px;
}
.top-right {
  top: 8px;
  right: 20px;
}
.top-center {
  top: 8px;
  right: 50%;
}
.middle-left {
  top: calc(50% - var(--height) / 2);
  left: 2px;
}
.middle-right {
  top: calc(50% - var(--height) / 2);
  right: 20px;
}
.middle-center {
  top: calc(50% - var(--height) / 2);
  right: 50%;
}
.bottom-left {
  bottom: 8px;
  left: 2px;
}
.bottom-right {
  bottom: 8px;
  right: 20px;
}
.bottom-center {
  bottom: 8px;
  right: 50%;
}

@keyframes ifade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes ifade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.fa-blink {
  animation-name: ifade;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-duration: 1000ms;
  animation-delay: 500ms;
}

.fa-blink-fast {
  animation-duration: 500ms;
}

.fa-blink-slow {
  animation-duration: 2000ms;
}
</style>

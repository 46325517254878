<script>
import {currentValueTypeCast} from "@/services/equipment-data.js";
export default {
  name: "SynopticEquipmentDataControl",
  props: {
    control: {
      type: Object,
      required: true,
      default: null
    },
    lastData: {
      type: Object,
      required: false,
      default: () => null
    },
    currentRect: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    isDirty() {
      return false; // implemented at child level (input controls only)
    },
    formatList() {
      return this.references.data_value_format_types || [];
    },
    dataFormat() {
      var id = this?.lastData?.value_format_type?.id || 0;
      return (
        this.formatList.find((i) => parseInt(i.id) == parseInt(id)) || null
      );
    },
    memorySize() {
      return this?.lastData?.memory_size || 1;
    },
    dataValueIndex() {
      return this.control.data_value_index;
    },
    dataValueCurrentIndex() {
      if (!(this.memorySize > 1) || !this.dataValueIndex) return -1;
      let vlr = -1;
      if (this.dataValueIndex.type == "constant") {
        vlr =
          this.dataValueIndex.value === ""
            ? -1
            : this.dataValueIndex.value ?? -1;
      } else if (this.dataValueIndex.data_id) {
        let data = this.$store.getters["dashboard/dataList"].find(
          ({id}) => parseInt(id) == parseInt(this.dataValueIndex.data_id)
        );
        vlr = parseInt(
          this?.dataValueIndex?.offset === ""
            ? 0
            : this?.dataValueIndex?.offset ?? 0
        );
        if (data && data.type !== "string") {
          const intValue = parseInt(data?.current_value?.value ?? 0);
          if (isNaN(vlr)) {
            vlr = this.$utils.evaluate(
              {
                data: {
                  current_value: {
                    value: intValue
                  }
                },
                $value: intValue,
                value: intValue
              },
              this?.dataValueIndex?.offset
            );
            vlr = isNaN(Number(vlr)) ? intValue : parseInt(vlr);
          } else {
            vlr = vlr + intValue;
          }
        }
      }
      return vlr >= 0 ? vlr : -1;
    },
    rawValue() {
      return this.dataValueCurrentIndex < 0
        ? this.$root.$formatter.rawValue(this?.lastData)
        : this.$root.$formatter.rawValue(
            this?.lastData,
            this.dataValueCurrentIndex
          );
    },
    expression() {
      return this?.control?.synopticComponent?.expression ?? "";
    },
    formattedValue() {
      let data = this.lastData;
      let vlr = "";
      let exp = this.expression || "";
      let fmt = this?.control?.format ? `|${this?.control?.format}` : "";
      if (!exp) exp = "${data?.current_value?.value}";
      let expHist = exp && exp.indexOf(".history") >= 0 && data.history_enabled;
      let entry = {
        ...(data || {}),
        perc: this.perc ?? this.rawValue,
        default: expHist
          ? ""
          : this.perc ??
            this?.control?.synopticComponent?.interval?.source == "percentage"
          ? this.perc
          : this.rawValue,
        template: `${exp}${fmt}`,
        current_value: {
          ...(data?.current_value || {}),
          value: this.isDirty ?? false ? this.iValue : this.rawValue
        },
        history:
          (expHist &&
            (this.$store.getters["history/entries"] || {})[data.id]) ||
          null
      };
      currentValueTypeCast(entry);
      vlr = this.$root.$formatter.format(entry);
      return vlr === "" || vlr == null ? "-" : vlr;
    }
  }
};
</script>
<template>
  <div>
    <div class="inner-panel">
      <label> {{ $t("column") }} [{{ columnName }}] </label>
      <div class="row">
        <div class="col-xs-6">
          <div class="form-group form-group-sm">
            <label style="padding: 5px 0; white-space: nowrap">
              <input type="checkbox" v-model="cellWordWrap" />
              <span class="small-checkbox-label"> {{ $t("word_wrap") }}</span>
            </label>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group form-group-sm no-padding">
            <input
              type="number"
              class="form-control no-padding text-center"
              step="1"
              min="10"
              max="100"
              v-model="cellWidth"
              :placeholder="$t('auto')"
            />
            <div class="inner-label">{{ $t("width") }}%</div>
          </div>
        </div>
      </div>
    </div>
    <portal to="contextmenu">
      <FloatPanel
        class="float-panel float-panel-cell-editor"
        :draggable="true"
        :handleSelf="true"
        :defaultPosition="{top: floatPanel.top, left: floatPanel.left}"
        :open="floatPanel.open"
        :title="address"
        @update:open="onFloatPanelUpdate($event)"
        @update:defaultPosition="onFloatPanelPosition($event)"
        @dragstart="floatPanel.dragging = true"
        @dragend="onDragEnd(floatPanel, $event)"
      >
        <div class="popup">
          <div class="popup-body">
            <div>
              <div class="nav-tabs-custom">
                <ul class="nav nav-tabs nav-justified">
                  <li
                    :class="{active: tab == 'properties'}"
                    @click.stop.prevent="tab = 'properties'"
                  >
                    <a href="#properties" data-toggle="tab">
                      <i class="fa fa-edit"></i>
                      {{ $t("properties") }}
                    </a>
                  </li>
                  <li
                    :class="{active: tab == 'style'}"
                    @click.stop.prevent="tab = 'style'"
                  >
                    <a href="#style" data-toggle="tab">
                      <i class="fa fa-magic"></i>
                      {{ $t("style") }}
                    </a>
                  </li>
                </ul>
                <div class="tab-content" v-if="!busy">
                  <div
                    id="properties"
                    class="active tab-pane tab-pane-properties"
                    v-if="tab == 'properties'"
                  >
                    <div v-if="dataSetColumnDetails">
                      <div class="form-group">
                        <div for="">{{ $t("dataset") }}</div>
                        <div class="input-group">
                          <div class="input-group-addon">
                            {{ $tc("field", 1) }}
                          </div>
                          <div class="form-control disabled">
                            {{ dataSetColumnDetails }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <TogglePanel
                      v-else
                      title="rich_text.cell_content"
                      :collapsed="false"
                    >
                      <!-- <label>{{ $t("data_source") }}</label> -->
                      <div class="dataSource">
                        <label
                          :for="`o${ix}`"
                          v-for="(item, ix) in dataSources"
                          :key="ix"
                          style="font-weight: normal"
                        >
                          <input
                            type="radio"
                            v-model="cellDataSource"
                            :id="`o${ix}`"
                            :value="item"
                          />
                          <span>
                            {{ $tc(item, 1) }}
                          </span>
                        </label>
                      </div>
                      <ControlDataSelector
                        v-if="cellDataSource == 'data'"
                        v-model="dataId"
                        :label="''"
                        :allowedTypes="['bool', 'float', 'int', 'string']"
                        :expressionToolbar="false"
                      />
                      <TestDataValue v-if="dataId" :dataId="dataId" />
                      <div class="form-group form-group-sm">
                        <div class="pull-left">
                          {{
                            $t(
                              cellDataSource == "constant"
                                ? "value"
                                : "expression"
                            )
                          }}
                          <span
                            v-if="cellDataSource != 'constant'"
                            class="expression-icon clicable"
                            :class="expressionAppend ? 'active' : ''"
                            @click.stop.prevent="
                              expressionAppend = !expressionAppend
                            "
                          >
                            ${}
                          </span>
                        </div>
                        <div style="clear: both">
                          <JSONPathPicker
                            :entry="entry"
                            :append="expressionAppend"
                            entryType="data"
                            v-model="expression"
                          />
                        </div>
                      </div>
                    </TogglePanel>
                    <TogglePanel title="format" :collapsed="false">
                      <DataFormatInput
                        v-model="format"
                        :inputTypeList="allowedFormatTypes"
                        label=""
                      />
                      <DataStateListForm
                        v-if="format == 'text_list'"
                        :equipmentData="equipmentData"
                        v-model="stateList"
                        style="margin-top: -20px"
                      />
                      <div
                        class="form-group form-group-sm"
                        v-if="format != 'text_list'"
                      >
                        <label
                          for="inp_default_value"
                          style="font-weight: normal"
                          >{{ $t("default_value") }}</label
                        >
                        <input
                          id="inp_default_value"
                          type="text"
                          class="form-control"
                          v-model="defaultValue"
                          autocomplete="off"
                        />
                      </div>
                    </TogglePanel>
                  </div>
                  <div
                    id="style"
                    class="active tab-pane tab-pane-style"
                    v-if="tab == 'style'"
                  >
                    <ControlStyleProperties
                      v-model="cellStyle"
                      :togglePanelIcons="{
                        collapse: 'fa-caret-square-o-up',
                        expand: 'fa-caret-square-o-down',
                        before: ''
                      }"
                      :textTogglePanelIcons="{
                        collapse: 'fa-caret-square-o-up',
                        expand: 'fa-caret-square-o-down',
                        before: ''
                      }"
                      style="position: relative"
                      :collapsed="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FloatPanel>
    </portal>
    <div class="text-center">
      <button class="btn btn-primary" @click.stop.prevent="toggleFloatPanel">
        <i class="fa fa-edit"></i>
        {{ $t("rich_text.cell_properties") }} [{{ address }}]
      </button>
    </div>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import TestDataValue from "@/components/synoptic/property-editor/controls/test-data-value.vue";
import DataFormatInput from "@/components/control-sidebar/property-editors/data-format-input.vue";
import DataStateListForm from "@/components/control-sidebar/property-editors/data-state-list-form.vue";
import FloatPanel from "@/components/editor/float-panel.vue";
const _fpsize = [500, 200];
let _fp = {
  h: _fpsize[1],
  w: _fpsize[0],
  y: parseInt((window.innerHeight - _fpsize[1]) / 2) - 150,
  x: parseInt((window.innerWidth - _fpsize[0]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);

const defCell = () => {
  return {
    value: "",
    data_id: "",
    data_source: "constant",
    state_list: null,
    format: "",
    default: "",
    style: {
      color: "#333",
      "background-color": "#fff",
      "box-shadow": "none",
      "text-align": "center",
      "font-family": "Source Sans Pro",
      "font-size": "11pt",
      "font-style": "normal",
      "font-weight": "normal",
      "text-decoration": "none",
      padding: "0px 0px 0px 0px",
      width: "auto",
      "white-space": "nowrap"
    }
  };
};

const defStateList = () => {
  return {
    dataSource: {
      type: "local",
      id: ""
    },
    default: "",
    items: []
  };
};

export default {
  name: "CellForm",
  props: {
    address: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    dataSetConfig: {
      type: Object,
      required: false,
      default: () => null
    },
    open: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    TogglePanel,
    ControlDataSelector,
    ControlStyleProperties,
    JSONPathPicker,
    TestDataValue,
    DataFormatInput,
    DataStateListForm,
    FloatPanel
  },
  data() {
    return {
      busy: false,
      cell: null,
      dataSources: ["constant", "data", "system"],
      expressionAppend: true,
      entry: "",
      tab: "properties",
      floatPanel: {
        top: `${_fp.y}px`,
        left: `${_fp.x}px`,
        open: false,
        dragging: false
      }
    };
  },
  computed: {
    extendedDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    columnName() {
      return this?.address.charAt(0);
    },
    dataSetColumnDetails() {
      let n = this.dataSetConfig;
      if (n && n.address && n.dataList.length) {
        let rcTarget = this.R1C1(n.address);
        let rc = this.R1C1(this.address);
        if (rc.row >= rcTarget.row) {
          // Sequence Column
          var sCol = n.sequenceColumn ? 0 : -1;
          // Time Column
          var tCol = n.timeColumn ? (sCol == 0 ? 1 : 0) : -1;
          // Data inicial column
          var dCol = (sCol >= 0 ? 1 : 0) + (tCol >= 0 ? 1 : 0);
          // current column
          var column = rc.column - 1;
          if (column == sCol) {
            return this.$t("row_number");
          } else if (column == tCol) {
            return this.$t("datetime");
          } else if (column >= dCol) {
            return this?.equipmentData?.name || this.cell.data_id;
          }
        }
      }
      return "";
    },
    cellStyle: {
      set(value) {
        let style = {...this.cellStyle, ...value};
        this.cellProperty("style", style);
      },
      get() {
        if (this.cell) {
          return this.cell.style
            ? this.cellProperty("style")
            : JSON.parse(JSON.stringify(defCell().style));
        }
        return null;
      }
    },
    cellWidth: {
      set(vlr) {
        let value = parseInt(vlr !== "" ? vlr : 0);
        this.cellStyle = {width: value ? value + "%" : "auto"};
      },
      get() {
        let vlr = "";
        if (this.cell) {
          let perc = this?.cell?.style?.width || "auto";
          vlr = perc != "auto" ? perc.replace(/\%/, "") : "";
        }
        return vlr;
      }
    },
    cellWordWrap: {
      set(vlr) {
        this.cellStyle = {"white-space": vlr ? "normal" : "nowrap"};
      },
      get() {
        let vlr = false;
        if (this.cell) {
          let wordwrap = (this?.cell?.style || {})["white-space"] || "normal";
          vlr = wordwrap == "normal";
        }
        return vlr;
      }
    },
    cellDataSource: {
      set(value) {
        if (value == "constant" || value == "system") {
          this.dataId = "";
          this.defaultValue = "";
          // if (this.format == "text_list") {
          //   this.format = "";
          // }
        } else {
          this.defaultValue = "-";
        }
        this.cellProperty("data_source", value);
        this.buildEntry();
      },
      get() {
        // return this?.cell?.data_source || (this.dataId ? "data" : "constant");
        return (
          this.cellProperty("data_source") ||
          (this.dataId ? "data" : "constant")
        );
      }
    },
    dataId: {
      set(value) {
        this.cellProperty("data_id", value);
        this.buildEntry();
      },
      get() {
        return this.cellProperty("data_id");
      }
    },
    expression: {
      set(value) {
        this.cellProperty("value", value);
      },
      get() {
        return this.cellProperty("value");
      }
    },
    format: {
      set(value) {
        this.cellProperty("format", value);
        // if (value != "text_list") {
        // this.stateList = null;
        // }
      },
      get() {
        return this.cellProperty("format");
      }
    },
    defaultValue: {
      set(value) {
        this.cellProperty("default", value);
      },
      get() {
        return this.cellProperty("default");
      }
    },
    stateList: {
      set(value) {
        this.cellProperty("stateList", value);
      },
      get() {
        if (this.format == "text_list") {
          return this.cellProperty("stateList") || defStateList();
        }
        return null;
      }
    },
    equipmentData() {
      if (!this.dataId) return null;
      return this.extendedDataList.find((i) => i.id == this.dataId);
    },
    allowedFormatTypes() {
      return ["date_time_format", "numeric_format", "text_list"];
    },
    isDataSetRange() {
      if (!this.dataSetConfig) return false;
      return true;
    },
    isOpen() {
      return this?.floatPanel?.open || false;
    }
  },
  watch: {
    value: {
      handler(n) {
        if (!n) return;
        if (isEqual(n, this.cell)) return;
        this.busy = true;
        this.$set(this, "cell", JSON.parse(JSON.stringify(n)));
        this.$nextTick(() => {
          this.busy = false;
        });
      },
      immediate: true,
      deep: true
    },
    open(n, o) {
      if (!o && n) {
        this.floatPanel.open = !this.floatPanel.open;
        if (this.floatPanel.open) {
          this.busy = true;
        }
      }
    },
    isOpen: {
      handler(n) {
        this.$root.$emit("cellform:visible", n ? true : false);
      },
      immediate: true
    }
  },
  methods: {
    R1C1(address) {
      return address && address.length >= 2
        ? {
            column: address.charCodeAt(0) - 64,
            row: parseInt(address.slice(1))
          }
        : null;
    },
    cellProperty(name, value) {
      if (!this.cell) return;
      if (name !== undefined && value !== undefined) {
        if (this.cell[name] != value) {
          // let cell = JSON.parse(JSON.stringify(this.cell));
          // cell[name] = value;
          // cell[name] = value;
          let entry = {};
          entry[name] = value;
          let cell = {...this.cell, ...entry};
          this.$set(this, "cell", cell);
          if (!isEqual(cell, this.value)) {
            this.$emit("input", cell);
          }
        }
      }
      if (name !== undefined) {
        return (this?.cell || {})[name];
      }
    },
    onFloatPanelPosition($event) {
      if (this.floatPanel.open) {
        this.busy = false;
        let $el = document.getElementsByClassName("float-panel-cell-editor");
        let h = $el && $el.length == 1 ? $el[0].clientHeight : _fp.h;
        let top = parseInt($event.top.replace(/\D+/, "") || 0);
        if (top <= document.documentElement.scrollTop) {
          top = h
            ? h / 2 + document.documentElement.scrollTop
            : document.documentElement.scrollTop;
        }
        this.floatPanel.top = `${top}px`;
        if (top + h >= window.innerHeight) {
          window.scrollTo(undefined, top);
        }
        // console.log(top);
      }
    },
    onFloatPanelUpdate(value) {
      if (this.floatPanel.open != value) {
        this.floatPanel.open = value;
      }
      if (this.floatPanel.open) {
        this.busy = true;
      }
    },
    toggleFloatPanel() {
      this.floatPanel.open = !this.floatPanel.open;
      if (this.floatPanel.open) {
        this.busy = true;
      }
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    },
    buildEntry() {
      let data = null;
      if (this.equipmentData) {
        //data = JSON.parse(JSON.stringify(this.equipmentData));
        data = JSON.parse(JSON.stringify(SelectableObjects.data));
        this.$utils.copyIfExists(this.equipmentData, data);
        if (data.history_enabled) {
          // let entries = JSON.parse(
          //   JSON.stringify(this.$store.getters["history/entries"] || {})
          // );
          // let history =
          //   (entries || {})[data.id] ||
          //   this.$store.getters["history/defHistoryData"];
          // delete history.samples; // it avoids too deep search
          let history = JSON.parse(JSON.stringify(SelectableObjects.history));
          // this.$utils.copyIfExists((entries || {})[data.id] || {}, history);
          data = {
            history: {
              ...history,
              samples: []
            },
            ...data
          };
        }
      } else if (this.cellDataSource == "system") {
        data = this.$store.getters.systemProperties;
      }
      this.entry = data;
    }
  },
  created() {
    this.buildEntry();
  }
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}

.inner-panel {
  padding-left: 4px;
}

.inner-panel .form-group {
  margin-left: 4px;
  margin-bottom: 4px;
  position: relative;
}

.inner-label {
  position: absolute;
  top: -8px;
  left: 5px;
  font-size: 8pt;
  background-color: white;
}

.small-checkbox-label {
  vertical-align: text-top;
  white-space: nowrap;
  font-size: 8pt;
}

.contentPanel {
  margin-top: -10px;
  /* border-top: 1px solid rgb(206, 206, 206); */
}

.dataSource {
  /* background: whitesmoke; */
  padding: 4px 0;
}

.dataSource > label > span {
  vertical-align: top;
  margin: 0 15px 0 2px;
}
.expression-icon {
  color: #666;
  font-size: 80%;
  font-weight: 600;
  letter-spacing: 0px;
  font-family: monospace;
}

.expression-icon.active {
  color: #025dca;
}

.list-group-item {
  padding: 5px;
}
.move-item:hover {
  cursor: move;
  opacity: 0.8;
}

.popup {
  position: relative;
}

.popup > .popup-body {
  width: 500px;
  min-width: 500px;
  min-height: 200px;
  max-height: 60vh;
  resize: both;
  overflow: auto;
  border-top: 1px solid lightgray;
}

.popup > .popup-body > div {
  width: 100%;
}

.tab-pane {
  padding: 0 5px;
}

.tab-pane.tab-pane-properties {
  min-height: 300px;
}
.tab-pane.tab-pane-style {
  min-height: 450px;
}

.nav-tabs-custom {
  padding-bottom: 0;
}

.nav-tabs-custom > .nav-tabs > li.header {
  font-weight: 600;
  padding: 2px 15px;
  color: #666;
  text-shadow: 0px 1px lightslategrey;
}

.field-group-label {
  padding: 5px 0;
}
</style>

<style>
/* Syntax highlighting for JSON objects */
ul.json-dict,
ol.json-array {
  list-style-type: none;
  margin: 0 0 0 1px;
  border-left: 1px dotted #ccc;
  padding-left: 1em;
}

.json-string {
  color: #0b7500;
}
.json-literal {
  color: #1a01cc;
  font-weight: bold;
}

/* Toggle button */
a.json-toggle {
  position: relative;
  color: inherit;
  text-decoration: none;
}
a.json-toggle:focus {
  outline: none;
}
a.json-toggle:before {
  color: #aaa;
  content: "\25BC"; /* down arrow */
  position: absolute;
  display: inline-block;
  width: 1em;
  left: -1em;
}
a.json-toggle.collapsed:before {
  content: "\25B6"; /* left arrow */
}

/* Collapsable placeholder links */
a.json-placeholder {
  color: #aaa;
  padding: 0 1em;
  text-decoration: none;
}
a.json-placeholder:hover {
  text-decoration: underline;
}

.pick-path:hover {
  cursor: pointer;
  color: orange;
}
</style>

<template>
  <div class="input-group time-interval">
    <input
      v-bind="$attrs"
      type="number"
      class="form-control"
      v-model.number="amount"
      @focus="$event.target.select()"
    />
    <div class="input-group-btn">
      <select class="form-control" v-model="unit">
        <option
          v-for="unit in parsedUnits"
          :key="unit.value"
          :value="unit.value"
          >{{ $tc(unit.name, amount) }}</option
        >
      </select>
    </div>
  </div>
</template>

<script>
import pick from "lodash/pick";

export default {
  name: "TimeInterval",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0
    },
    units: {
      type: String,
      default: "s,m,h,d",
      validator(val) {
        return val
          .split(",")
          .every((unit) => ["s", "m", "h", "d"].includes(unit));
      }
    },
    decimals: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unit: "",
      amount: 0,
      baseUnits: ["s", "m", "h", "d"],
      unitMap: { s: 1, m: 60, h: 3600, d: 86400 }
    };
  },
  computed: {
    parsedUnits() {
      let nameMap = { s: "second", m: "minute", h: "hour", d: "day" };
      return this.units
        .split(",")
        .map((opt) => ({ name: nameMap[opt], value: opt }));
    },
    sortedUnits() {
      const od = (s) => ({ s: 1, m: 2, h: 3, d: 4 }[s]);
      return this.units.split(",").sort((a, b) => od(a) - od(b));
    },
    absoluteAmount() {
      return this.amount * this.unitMap[this.unit];
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val == this.absoluteAmount) return;
        this.parseAmount(val);
      }
    },
    absoluteAmount(val) {
      if (val != this.value) {
        this.$emit("input", val);
      }
    },
    units() {
      if (!this.hasUnit(this.unit)) {
        let unit = this.closestUnitTo(this.unit);
        let amount = this.absoluteAmount / this.unitMap[unit];
        this.unit = unit;
        this.amount = this.decimals ? amount : Math.round(amount);
      }
    }
  },
  methods: {
    parseAmount(val) {
      let amount;
      // >= 1 day
      if (val >= 86400 && this.hasUnit("d")) {
        amount = val / 86400;
        this.unit = "d";
      } else if (val >= 3600 && this.hasUnit("h")) {
        // >= 1 hour
        amount = val / 3600;
        this.unit = "h";
      } else if (val > 60 && this.hasUnit("m")) {
        // >= 1 minute
        amount = val / 60;
        this.unit = "m";
      } else {
        amount = val;
        this.unit = "s";
      }

      this.amount = this.decimals ? amount : Math.round(amount);
    },
    hasUnit(opt) {
      return this.units.split(",").includes(opt);
    },
    closestUnitTo(lastUnit) {
      let unitOrder = { s: 1, m: 2, h: 3, d: 4 };
      let availableUnits = pick(unitOrder, this.units.split(","));
      // searches closest unit to lastUnit
      // that comes before it
      let { unit } = Object.keys(availableUnits).reduce(
        ({ diff, unit }, un) => {
          let thisDiff = Math.abs(unitOrder[lastUnit] - availableUnits[un]);
          return thisDiff < diff && availableUnits[un] <= unitOrder[lastUnit]
            ? { diff: thisDiff, unit: un }
            : { diff, unit };
        },
        { diff: 4, unit: null }
      );

      if (unit) return unit;

      // if no unit comes before it
      // searches for closest that comes after
      ({ unit } = Object.keys(availableUnits).reduce(
        ({ diff, unit }, un) => {
          let thisDiff = Math.abs(unitOrder[lastUnit] - availableUnits[un]);
          return diff == null ||
            (thisDiff < diff && availableUnits[un] > unitOrder[lastUnit])
            ? { diff: thisDiff, unit: un }
            : { diff, unit };
        },
        { diff: null, unit: null }
      ));

      return unit;
    }
  }
};
</script>

<style lang="scss" scoped>
.time-interval {
  display: inline-flex;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    -moz-appearance: textfield;
    flex: 0 1 20%;
    text-align: center;
  }

  .input-group-btn {
    flex: 1;

    select.form-control {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
</style>

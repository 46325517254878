<template>
  <div class="box-header" v-if="!noTitle" ref="boxHeader">
    <DashboardPanelTitle
      v-if="!noTitle"
      :panel="panel"
      :connector="equipment"
      :isEditing="isEditing"
      @click.stop.prevent="$emit('title:click')"
    />
    <slot name="toolbar"></slot>
    <template v-if="isEditing">
      <portal to="panel-editor-toolbar">
        <slot name="panel-editor-toolbar"></slot>
      </portal>
    </template>
  </div>
</template>

<script>
import DashboardPanelTitle from "@/components/dashboard-panel-title.vue";
import {panelTitle} from "@/components/dashboard-panel-title.vue";
export default {
  name: "DashboardPanelHeader",
  props: {
    panel: {
      type: Object,
      required: true,
      default: null
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    mode: {
      type: String,
      required: false,
      default: "viewer"
    },
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: {
    DashboardPanelTitle
  },
  computed: {
    title() {
      return panelTitle(this);
    },
    noTitle() {
      if (this.mode == "editor") return false;
      return this.title === "" && this.panel.toolbar.length == 0;
    }
  },
  watch: {
    noTitle: {
      handler(n) {
        this.$emit("noTitle", n);
        this.sizeChange();
      },
      immediate: true
    }
  },
  methods: {
    sizeChange() {
      if (!this.$refs.boxHeader) return;
      const r = this.$refs.boxHeader.getBoundingClientRect();
      this.$emit("resized", {width: r.width, height: r.height});
    }
  },
  mounted() {
    this.sizeChange();
  }
};
</script>

<style scoped>
.box-header {
  padding: 0 5px;
}
</style>